import { useState } from "react";
import Weblayout from "../../layout/Weblayout";
import { useDispatch } from "react-redux";
import ProfileAction from "../../stores/action/profileAction";

function ChangePassword() {
  const [oldPassword, setOldPassword] = useState<any>();
  const [newPassword, setNewPassword] = useState<any>();
  const [newPasswordConfirmation, setnewPasswordConfirmation] = useState<any>();
  const [oldPasswordShow, setOldPasswordShow] = useState<any>();
  const [newPasswordShow, setNewPasswordShow] = useState<any>();
  const [newPasswordConfirmationShow, setNewPasswordConfirmationShow] =
    useState<any>();
  const dispatch = useDispatch<any>();

  const handelSubmit = async (e: any) => {
    e.preventDefault();
    try {
      await dispatch(
        ProfileAction.changePassword({
          old_password: oldPassword,
          new_password: newPassword,
          new_password_confirmation: newPasswordConfirmation,
        })
      );
      setOldPassword("");
      setNewPassword("");
      setnewPasswordConfirmation("");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Weblayout pageName={"ChangePassword"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <ul className="breadcrumb breadcrumb-style mb-0">
                  <li className="breadcrumb-item">
                    <h4 className="page-title">Change Password</h4>
                  </li>
                  <li className="breadcrumb-item bcrumb-1">
                    <a href="/dashboard">
                      <i className="fas fa-home"></i> Home
                    </a>
                  </li>
                  <li className="breadcrumb-item active">Change Password</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 m-auto">
              <form onSubmit={handelSubmit}>
                <div className="card card-body">
                  <div className="col-md-12">
                    <label>
                      Current Password <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <div className="form-line">
                        <input
                          type={oldPasswordShow ? "text" : "password"}
                          className="form-control"
                          placeholder="Enter Current Password"
                          value={oldPassword}
                          onChange={(e) => setOldPassword(e.target.value)}
                        />
                      </div>
                      <span
                        className="input-group-addon cursor-pointer"
                        onClick={() => setOldPasswordShow(!oldPasswordShow)}>
                        <i
                          className={` ${
                            oldPasswordShow ? "fa fa-eye-slash" : "fa fa-eye"
                          } `}></i>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label>
                      New Password <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <div className="form-line">
                        <input
                          type={newPasswordShow ? "text" : "password"}
                          className="form-control"
                          placeholder="Enter New Password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </div>
                      <span
                        className="input-group-addon cursor-pointer"
                        onClick={() => setNewPasswordShow(!newPasswordShow)}>
                        <i
                          className={` ${
                            newPasswordShow ? "fa fa-eye-slash" : "fa fa-eye"
                          }`}></i>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label>
                      Confirm Password <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <div className="form-line">
                        <input
                          type={
                            newPasswordConfirmationShow ? "text" : "password"
                          }
                          className="form-control"
                          placeholder="Enter Confirm Password"
                          value={newPasswordConfirmation}
                          onChange={(e) =>
                            setnewPasswordConfirmation(e.target.value)
                          }
                        />
                      </div>
                      <span
                        className="input-group-addon cursor-pointer"
                        onClick={() =>
                          setNewPasswordConfirmationShow(
                            !newPasswordConfirmationShow
                          )
                        }>
                        <i
                          className={`${
                            newPasswordConfirmationShow
                              ? "fa fa-eye-slash"
                              : "fa fa-eye"
                          }`}></i>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        Change Password
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Weblayout>
  );
}

export default ChangePassword;
