const AuthType = {
  EMPLOYEE_LOGIN: "EMPLOYEE_LOGIN",
  RESET_AUTH: "RESET_AUTH",
};

export interface User {
  id: number;
  name: string;
  mobile: string;
  email: string;
  username: string;
  created_at: any;
}

export interface LogIn {
  email: string;
  password: string;
}

export interface LogOut {
  tokens: any;
}

export default AuthType;
