import { useDispatch, useSelector } from "react-redux";
import Weblayout from "../../layout/Weblayout";
import { RootState } from "../../stores";
import { useCallback, useEffect, useState } from "react";
import ProfileAction from "../../stores/action/profileAction";
import UpdateProfile from "../../components/profile/UpdateProfile";

function ViewProfile() {
  const [modal, setModal] = useState<any>(false);
  const [modalType, setModalType] = useState<any>("profile");
  const dispatch = useDispatch<any>();
  const { profileData } = useSelector((state: RootState) => state.profile);
  const getProfileData = useCallback(async () => {
    await dispatch(ProfileAction.getProfile());
  }, [dispatch]);

  useEffect(() => {
    getProfileData();
  }, [getProfileData]);

  const openModal = (type: any) => {
    setModal(true);
    setModalType(type);
  };

  return (
    <Weblayout pageName={"ViewProfile"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <ul className="breadcrumb breadcrumb-style mb-0">
                  <li className="breadcrumb-item">
                    <h4 className="page-title">Profile</h4>
                  </li>
                  <li className="breadcrumb-item bcrumb-1">
                    <a href="/dashboard">
                      <i className="fas fa-home"></i> Home
                    </a>
                  </li>
                  <li className="breadcrumb-item active">Profile</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card card-body">
            <div className="container rounded bg-white">
              <div className="row">
                <div className="col-md-3 border-right">
                  <div className="d-flex flex-column align-items-center text-center p-3 py-1">
                    <img
                      className="rounded-circle border p-2 mt-5"
                      width="150px"
                      src={profileData?.profile_img}
                    />
                    <span className="font-weight-bold text-dark">
                      {profileData?.name}
                    </span>
                    <span className="text-black-50">{profileData?.email}</span>
                    <span> </span>
                  </div>
                </div>
                <div className="col-md-3 border-right">
                  <div className="p-1 py-1">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h4 className="text-right text-dark">Personal Details</h4>
                      <UpdateProfile
                        modal={modal}
                        setModal={setModal}
                        modalType={modalType}
                        getProfileData={getProfileData}
                        profileData={profileData}
                      />
                      <button
                        className="btn btn-success btn-sm"
                        onClick={() => openModal("profile")}>
                        Edit
                      </button>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <label className="labels">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          value={profileData?.name}
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="labels">Father Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={profileData?.f_name}
                          placeholder="Father Name"
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="labels">Email Id</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email Id"
                          value={profileData?.email}
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="labels">Mobile Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Mobile Number"
                          value={profileData?.mobile}
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="labels">State</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="State"
                          value={profileData?.sName}
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="labels">District</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="District"
                          value={profileData?.dName}
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="labels">Pin Code</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Pin Code"
                          value={profileData?.pincode}
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="labels">Present Address</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Present Address"
                          value={profileData?.present_address}
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="labels">Permanent Address</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Permanent Address"
                          value={profileData?.permanent_address}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 border-right">
                  <div className="p-1 py-1">
                    <div className="d-flex justify-content-between align-items-center experience">
                      <h4 className="text-right text-dark">Bank Details</h4>
                      <button
                        className="btn btn-success btn-sm"
                        onClick={() => openModal("bank")}>
                        Edit
                      </button>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <label className="labels">Account Holder Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Account Holder Name"
                          value={profileData?.account_holder_name}
                        />
                      </div>{" "}
                      <br />
                      <div className="col-md-12">
                        <label className="labels">Account Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Account Number"
                          value={profileData?.account_number}
                        />
                      </div>
                      <br />
                      <div className="col-md-12">
                        <label className="labels">Bank Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Bank Name"
                          value={profileData?.bank_name}
                        />
                      </div>
                      <br />
                      <div className="col-md-12">
                        <label className="labels">IFSC Code</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="IFSC Code"
                          value={profileData?.ifsc_code}
                        />
                      </div>
                      <br />
                      <div className="col-md-12">
                        <label className="labels">Branch Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Branch Name"
                          value={profileData?.branch_name}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="p-1 py-1">
                    <div className="d-flex justify-content-between align-items-center experience">
                      <h4 className="text-right text-dark">KYC Details</h4>
                      <button
                        className="btn btn-success btn-sm"
                        onClick={() => openModal("kyc")}>
                        Edit
                      </button>
                    </div>
                    <div className="row mt-3">
                      <article className="col-md-12 col-sm-12 portfolio-item apps">
                        <div className="portfolio-thumb in">
                          <a href="#" className="main-link">
                            <img
                              className="img-responsive img-center"
                              src={profileData?.pan_img}
                              alt=""
                            />
                            <span className="project-title">PAN </span>
                            <span className="overlay-mask"></span>
                          </a>
                        </div>
                        <h5 className="text-center mt-2 text-dark">
                          PAN No. {profileData?.pan_no}
                        </h5>
                      </article>

                      <article className="col-md-12 col-sm-12 portfolio-item apps">
                        <div className="portfolio-thumb in">
                          <a href="#" className="main-link">
                            <img
                              className="img-responsive img-center"
                              src={profileData?.aadhar_img}
                              alt=""
                            />
                            <span className="project-title">Aadhar </span>
                            <span className="overlay-mask"></span>
                          </a>
                        </div>
                        <h5 className="text-center mt-2 text-dark">
                          Aadhar No. {profileData?.aadhar_no}
                        </h5>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Weblayout>
  );
}

export default ViewProfile;
