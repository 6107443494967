import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { EMPLOYEE_TOKEN } from "../../utils/Constants";
import AuthAction from "../../stores/action/authAction";
import toast, { Toaster } from "react-hot-toast";
type FormData = {
  email: any;
  password: any;
};
function Login() {
  const [showPassword, setShowPassword] = useState<any>();
  const { register, handleSubmit, formState } = useForm<FormData>();
  const dispatch = useDispatch()<any>;
  const { authenticated, token } = useSelector(
    (state: RootState) => state.auth
  );
  const page = useNavigate();

  useEffect(() => {
    const tokens = localStorage.getItem(EMPLOYEE_TOKEN);
    if ((authenticated && token) || tokens) {
      page("/dashboard");
      toast.success("Welcome to ASL Employee Panel !!");
    }
  }, [authenticated, page]);

  const { isSubmitting } = formState;
  const onSubmit = handleSubmit(async (data) => {
    await dispatch(
      AuthAction.logIn({
        email: data.email,
        password: data.password,
      })
    );
  });

  return (
    <>
      <div className="login">
        <link href="assets/css/pages/extra_pages.css" rel="stylesheet" />

        <div className="limiter text-center">
          <div className="container-login100">
            <div className="wrap-login100">
              <form
                className="login100-form validate-form text-center"
                onSubmit={onSubmit}>
                <span className="login100-form-title p-b-10">Login</span>
                <img
                  className="p-b-20"
                  src="assets/images/asl_logo.png"
                  width={300}></img>
                <div className="wrap-input100 validate-input">
                  <div className="input-group">
                    <div className="inline-form">
                      <input
                        className="input100"
                        type="text"
                        {...register("email")}
                        placeholder="Username"
                      />
                    </div>
                    <span className="input-group-addon">
                      <i className="fa fa-user"></i>
                    </span>
                  </div>
                </div>
                <div className="wrap-input100 validate-input">
                  <div className="input-group">
                    <div className="inline-form">
                      <input
                        className="input100"
                        type={showPassword ? "text" : "password"}
                        {...register("password")}
                        placeholder="Password"
                      />
                    </div>
                    <span
                      className="input-group-addon"
                      onClick={() => setShowPassword(!showPassword)}>
                      <i
                        className={` ${
                          showPassword ? "fa fa-eye-slash" : "fa fa-eye"
                        }`}></i>
                    </span>
                  </div>
                </div>

                <div className="container-login100-form-btn">
                  <button
                    type="submit"
                    className="login100-form-btn"
                    disabled={isSubmitting}>
                    {isSubmitting && (
                      <span className="spinner-border spinner-border-sm mr-1"></span>
                    )}
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
