import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
} from "redux";
import thunk from "redux-thunk";

import AuthReducer from "./reducers/authReducers";
import ProfileReducers from "./reducers/profileReducers";
import AttendanceReducers from "./reducers/attendanceReducers";
import LeaveReducers from "./reducers/leaveReducers";

const rootReducer = combineReducers({
  auth: AuthReducer,
  profile: ProfileReducers,
  attendance: AttendanceReducers,
  leaveRequest: LeaveReducers,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export type RootState = ReturnType<typeof rootReducer>;

export default store;
