import { Link } from "react-router-dom";

function Header({ logoutHandle }: any) {
  function menuCheck() {
    return false;
  }
  return (
    <>
      <nav className="navbar">
        <div className="container-fluid">
          <div className="navbar-header">
            <Link
              to="#"
              onClick={menuCheck}
              className="navbar-toggle collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#navbar-collapse"
              aria-expanded="false"></Link>
            <Link to="#" onClick={menuCheck} className="bars"></Link>
            <Link className="navbar-brand" to="/dashboard">
              <img
                className="logo-name manage_logo"
                src="http://admin.abhhyamsecure.com/assets/images/asl_logo.png"></img>
            </Link>
          </div>
          <div className="collapse navbar-collapse" id="navbar-collapse">
            <ul className="pull-left">
              <li>
                <Link to="#" onClick={menuCheck} className="sidemenu-collapse">
                  <i className="fa fa-bars"></i>
                </Link>
              </li>
            </ul>
            <ul className="nav navbar-nav navbar-right">
              <li className="fullscreen">
                <Link to="javascript:;" className="fullscreen-btn">
                  <i className="fas fa-expand"></i>
                </Link>
              </li>

              <li className="dropdown">
                <Link
                  to="#"
                  onClick={menuCheck}
                  className="dropdown-toggle"
                  data-bs-toggle="dropdown"
                  role="button">
                  <i className="far fa-bell"></i>
                  <span className="notify"></span>
                  <span className="heartbeat"></span>
                </Link>
                <ul className="dropdown-menu pullDown">
                  <li className="header">NOTIFICATIONS</li>
                  <li className="body">
                    <ul className="menu">
                      <li>
                        <Link to="#" onClick={menuCheck}>
                          <span className="table-img msg-user">
                            <img src="assets/images/user/user1.jpg" alt="" />
                          </span>
                          <span className="menu-info">
                            <span className="menu-title">Sarah Smith</span>
                            <span className="menu-desc">
                              <i className="material-icons">access_time</i> 14
                              mins ago
                            </span>
                            <span className="menu-desc">
                              Please check your email.
                            </span>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#" onClick={menuCheck}>
                          <span className="table-img msg-user">
                            <img src="assets/images/user/user2.jpg" alt="" />
                          </span>
                          <span className="menu-info">
                            <span className="menu-title">Airi Satou</span>
                            <span className="menu-desc">
                              <i className="material-icons">access_time</i> 22
                              mins ago
                            </span>
                            <span className="menu-desc">
                              Please check your email.
                            </span>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#" onClick={menuCheck}>
                          <span className="table-img msg-user">
                            <img src="assets/images/user/user3.jpg" alt="" />
                          </span>
                          <span className="menu-info">
                            <span className="menu-title">John Doe</span>
                            <span className="menu-desc">
                              <i className="material-icons">access_time</i> 3
                              hours ago
                            </span>
                            <span className="menu-desc">
                              Please check your email.
                            </span>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#" onClick={menuCheck}>
                          <span className="table-img msg-user">
                            <img src="assets/images/user/user4.jpg" alt="" />
                          </span>
                          <span className="menu-info">
                            <span className="menu-title">Ashton Cox</span>
                            <span className="menu-desc">
                              <i className="material-icons">access_time</i> 2
                              hours ago
                            </span>
                            <span className="menu-desc">
                              Please check your email.
                            </span>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#" onClick={menuCheck}>
                          <span className="table-img msg-user">
                            <img src="assets/images/user/user5.jpg" alt="" />
                          </span>
                          <span className="menu-info">
                            <span className="menu-title">Cara Stevens</span>
                            <span className="menu-desc">
                              <i className="material-icons">access_time</i> 4
                              hours ago
                            </span>
                            <span className="menu-desc">
                              Please check your email.
                            </span>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#" onClick={menuCheck}>
                          <span className="table-img msg-user">
                            <img src="assets/images/user/user6.jpg" alt="" />
                          </span>
                          <span className="menu-info">
                            <span className="menu-title">Charde Marshall</span>
                            <span className="menu-desc">
                              <i className="material-icons">access_time</i> 3
                              hours ago
                            </span>
                            <span className="menu-desc">
                              Please check your email.
                            </span>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#" onClick={menuCheck}>
                          <span className="table-img msg-user">
                            <img src="assets/images/user/user7.jpg" alt="" />
                          </span>
                          <span className="menu-info">
                            <span className="menu-title">John Doe</span>
                            <span className="menu-desc">
                              <i className="material-icons">access_time</i>{" "}
                              Yesterday
                            </span>
                            <span className="menu-desc">
                              Please check your email.
                            </span>
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="footer">
                    <Link to="#" onClick={menuCheck}>
                      View All Notifications
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="dropdown user_profile">
                <div className="dropdown-toggle" data-bs-toggle="dropdown">
                  <img
                    src="http://admin.abhhyamsecure.com/assets/images/asl_logo.jpeg"
                    alt="user"
                    width={30}
                  />
                </div>
                <ul className="dropdown-menu pullDown">
                  <li className="body">
                    <ul className="user_dw_menu">
                      <li>
                        <Link to="/profile" onClick={menuCheck}>
                          <i className="material-icons">person</i>Profile
                        </Link>
                      </li>
                      <li>
                        <Link to="/change-password" onClick={menuCheck}>
                          <i className="material-icons">vpn_key</i>Change
                          Password
                        </Link>
                      </li>
                      <li>
                        <Link to="#" onClick={() => logoutHandle()}>
                          <i className="material-icons">power_settings_new</i>
                          Logout
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
