import { useCallback, useEffect, useState } from "react";
import Weblayout from "../../layout/Weblayout";
import { useDispatch, useSelector } from "react-redux";
import AttendanceAction from "../../stores/action/attendanceAction";
import { RootState } from "../../stores";

function MarkAttendance() {
  const [date, set_date] = useState<any>();
  const [in_time, set_in_time] = useState<any>();
  const [out_time, set_out_time] = useState<any>();
  const dispatch = useDispatch<any>();
  const [search, set_search] = useState<any>();
  const [limit, set_limit] = useState<any>(10);
  const [page, set_page] = useState<any>(1);
  const [editableData, setEditableData] = useState<any>();
  const [type, set_type] = useState<any>("add");

  const handelSubmit = async (e: any) => {
    e.preventDefault();
    try {
      if (type === "update") {
        await dispatch(
          AttendanceAction.updateAttendance({
            id: editableData.id,
            date: date,
            in_time: in_time,
            out_time: out_time,
          })
        );
      } else {
        await dispatch(
          AttendanceAction.addAttendance({
            date: date,
            in_time: in_time,
            out_time: out_time,
          })
        );
      }
      getAttendanceList();
      set_date("");
      set_in_time("");
      set_out_time("");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (type === "update") {
      set_date(editableData.date);
      set_in_time(editableData?.in_time);
    }

    if (type === "update" && editableData?.out_time !== null) {
      set_out_time(editableData?.out_time);
    }
  }, [editableData]);

  const { attendanceList, currentPage, lastPage, totalData, from, to } =
    useSelector((state: RootState) => state.attendance);

  const maxPagesToShow = 5; // Maximum number of page links to show
  const pagination = [];

  const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getAttendanceList = useCallback(async () => {
    await dispatch(
      AttendanceAction.getAttendance({
        search: search,
        limit: limit,
        page: page,
      })
    );
  }, [dispatch, search, limit, page]);

  useEffect(() => {
    getAttendanceList();
  }, [getAttendanceList, search, limit, page]);

  const getEditableDate = (dataList: any) => {
    setEditableData(dataList);
  };

  console.log(totalData);

  return (
    <Weblayout pageName={"MarkAttendance"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <ul className="breadcrumb breadcrumb-style mb-0">
                  <li className="breadcrumb-item">
                    <h4 className="page-title">Mark Attendance</h4>
                  </li>
                  <li className="breadcrumb-item bcrumb-1">
                    <a href="/dashboard">
                      <i className="fas fa-home"></i> Home
                    </a>
                  </li>
                  <li className="breadcrumb-item active">Mark Attendance</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card card-body">
            <div className="container rounded bg-white">
              <form onSubmit={handelSubmit}>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="form-label">
                        Select Date <span className="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={date}
                        onChange={(e) => set_date(e.target.value)}
                        disabled={type === "update" ? true : false}></input>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="form-label">
                        Select In Time <span className="text-danger">*</span>
                      </label>
                      <input
                        type="time"
                        className="form-control"
                        value={in_time}
                        onChange={(e) => set_in_time(e.target.value)}
                        disabled={type === "update" ? true : false}
                      />
                    </div>
                  </div>
                  {type === "update" ? (
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="form-label">
                          Select Out Time <span className="text-danger">*</span>
                        </label>
                        <input
                          type="time"
                          className="form-control"
                          value={out_time}
                          onChange={(e) => set_out_time(e.target.value)}
                        />
                      </div>
                    </div>
                  ) : null}

                  <div className="col-md-3">
                    <button type="submit" className="btn btn-success mt-4">
                      {type === "update" ? "Update" : "Submit"}
                    </button>
                  </div>
                </div>
              </form>

              <div
                className=" mb-2 d-flex
              justify-content-between
              align-items-end">
                <div className="col-md-3">
                  <label className="mb-2">Show Entries</label>
                  <select
                    onChange={(e) => set_limit(e.target.value)}
                    id="defaultSelect"
                    className="form-select">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-3">
                  <div className="input-group input-group-merge mb-1">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      onChange={(e) => set_search(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <table
                  id="basicTable"
                  className="table table-hover table-checkable order-column contact_list">
                  <thead>
                    <tr>
                      <th className="center">#</th>
                      <th className="center"> Date</th>
                      <th className="center"> In Time</th>
                      <th className="center"> Out Time</th>
                      <th className="center"> Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {attendanceList?.length > 0 ? (
                      attendanceList.map((dataList: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{dataList.date}</td>
                            <td>{dataList.in_time}</td>
                            <td>{dataList.out_time}</td>
                            <td>
                              <a href="javascript:void(0);">
                                <i
                                  className="fa fa-edit"
                                  onClick={() => {
                                    set_type("update");
                                    getEditableDate(dataList);
                                  }}></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={10}>No matching records found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {attendanceList?.length > 0 ? (
                <div className="row mt-2 justify-content-between">
                  <div className="col-md-auto me-auto ">
                    <div className="dt-info text-dark">
                      Showing {from} to {to} of {totalData} entries
                    </div>
                  </div>
                  <div className="col-md-auto ms-auto ">
                    <div className="dt-paging paging_full_numbers">
                      <ul className="pagination">
                        <li
                          className="dt-paging-button page-item disabled"
                          onClick={() => set_page(1)}>
                          <a
                            className="page-link first"
                            aria-controls="tableGroup"
                            aria-disabled="true"
                            aria-label="First"
                            data-dt-idx="first">
                            <span aria-hidden="true">&laquo;</span>
                          </a>
                        </li>
                        <li
                          className={`dt-paging-button page-item prev ${
                            page === 1 ? "disabled" : ""
                          }`}
                          onClick={() =>
                            page === 1 ? "" : set_page(currentPage - 1)
                          }>
                          <a
                            className="page-link previous"
                            aria-controls="tableGroup"
                            aria-disabled="true"
                            aria-label="Previous"
                            data-dt-idx="previous">
                            <span aria-hidden="true">&lt;</span>
                          </a>
                        </li>
                        {pagination.map((p) => {
                          return (
                            <li
                              className={`dt-paging-button page-item ${
                                currentPage === p ? "active" : ""
                              }`}
                              onClick={() => set_page(p)}>
                              <a
                                className="page-link"
                                href="javascript:void(0);"
                                aria-controls="tableGroup"
                                aria-current="page"
                                data-dt-idx="0">
                                {p}
                              </a>
                            </li>
                          );
                        })}

                        <li
                          className={`dt-paging-button page-item next ${
                            lastPage === page ? "disabled" : ""
                          }`}
                          onClick={() =>
                            lastPage === page ? "" : set_page(currentPage + 1)
                          }>
                          <a
                            href="javascript:void(0);"
                            className="page-link next"
                            aria-controls="tableGroup"
                            aria-label="Next"
                            data-dt-idx="next">
                            <span aria-hidden="true">&gt;</span>
                          </a>
                        </li>
                        <li
                          className="dt-paging-button page-item"
                          onClick={() => set_page(lastPage)}>
                          <a
                            href="javascript:void(0);"
                            className="page-link last"
                            aria-controls="tableGroup"
                            aria-label="Last"
                            data-dt-idx="last">
                            <span aria-hidden="true">&raquo;</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </Weblayout>
  );
}

export default MarkAttendance;
