import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axios from "axios";
import { BASE_URL, EMPLOYEE_TOKEN } from "../../utils/Constants";
import ProfileType, {
  ChangePassword,
  GetDistrict,
  UpdateBankDetails,
  UpdateKYCDetails,
  UpdateProfile,
} from "../type/profileType";
import toast from "react-hot-toast";

interface Action {
  type: string;
  payload?: any;
}

const ProfileAction = {
  getProfile:
    (): ThunkAction<void, RootState, null, Action> => async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(EMPLOYEE_TOKEN);
        const result: any = await axios.get(BASE_URL + "profile/get", {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
            Accept: "application/json",
          },
        });

        dispatch({
          type: ProfileType.SET_PROFILE_DATA,
          payload: result.data.data,
        });
      } catch (error: any) {
        console.log(error.message);
      }
    },

  updateProfile:
    (data: UpdateProfile): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(EMPLOYEE_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "profile/update",
          {
            profile_img: data.profile_img,
            name: data.name,
            f_name: data.f_name,
            email: data.email,
            mobile: data.mobile,
            state: data.state,
            district: data.district,
            pincode: data.pincode,
            present_address: data.present_address,
            permanent_address: data.permanent_address,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );
        dispatch({
          type: ProfileType.UPDATE_PROFILE_DATA,
        });
        if (result.data.status === true) {
          toast.success(result.data.msg);
        } else {
          toast.error(result.data.msg);
        }
      } catch (error: any) {
        console.log(error.message);
      }
    },

  updateBankDetails:
    (data: UpdateBankDetails): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(EMPLOYEE_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "profile/update-bank-details",
          {
            account_holder_name: data.account_holder_name,
            account_number: data.account_number,
            bank_name: data.bank_name,
            branch_name: data.branch_name,
            ifsc_code: data.ifsc_code,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );
        dispatch({
          type: ProfileType.UPDATE_BANK_DETAILS,
        });
        if (result.data.status === true) {
          toast.success(result.data.msg);
        } else {
          toast.error(result.data.msg);
        }
      } catch (error: any) {
        console.log(error.message);
      }
    },

  updateKYCDetails:
    (data: UpdateKYCDetails): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(EMPLOYEE_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "profile/update-kyc-details",
          {
            pan_img: data.pan_img,
            pan_no: data.pan_no,
            aadhar_img: data.aadhar_img,
            aadhar_no: data.aadhar_no,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );
        dispatch({
          type: ProfileType.UPDATE_KYC_DETAILS,
        });
        if (result.data.status === true) {
          toast.success(result.data.msg);
        } else {
          toast.error(result.data.msg);
        }
      } catch (error: any) {
        console.log(error.message);
      }
    },

  getState:
    (): ThunkAction<void, RootState, null, Action> => async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(EMPLOYEE_TOKEN);
        const result: any = await axios.get(BASE_URL + "state", {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
          },
        });
        dispatch({
          type: ProfileType.GET_STATE_LIST,
          payload: {
            data: result.data.data,
          },
        });
      } catch (error) {
        console.log(error);
      }
    },

  getDistrict:
    (data: GetDistrict): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(EMPLOYEE_TOKEN);
        const result: any = await axios.get(
          BASE_URL + "district/" + data.state_id,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: ProfileType.GET_DISTRICT_LIST,
          payload: {
            data: result.data.data,
          },
        });
      } catch (error) {
        console.log("error", error);
      }
    },

  changePassword:
    (data: ChangePassword): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(EMPLOYEE_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "profile/change-password",
          {
            old_password: data.old_password,
            new_password: data.new_password,
            new_password_confirmation: data.new_password_confirmation,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );
        dispatch({
          type: ProfileType.CHANGE_PASSWORD,
        });
        if (result.data.status === true) {
          toast.success(result.data.msg);
        } else {
          toast.error(result.data.msg);
        }
      } catch (error: any) {
        console.log(error.message);
      }
    },
};

export default ProfileAction;
