const ProfileType = {
  SET_PROFILE_DATA: "SET_PROFILE_DATA",
  UPDATE_PROFILE_DATA: "UPDATE_PROFILE_DATA",
  UPDATE_BANK_DETAILS: "UPDATE_BANK_DETAILS",
  UPDATE_KYC_DETAILS: "UPDATE_KYC_DETAILS",
  GET_STATE_LIST: "GET_STATE_LIST",
  GET_DISTRICT_LIST: "GET_DISTRICT_LIST",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
};

export interface UpdateProfile {
  profile_img: string;
  name: string;
  f_name: string;
  email: string;
  mobile: string;
  state: number;
  district: number;
  pincode: number;
  present_address: string;
  permanent_address: string;
}

export interface UpdateBankDetails {
  account_holder_name: string;
  account_number: number;
  bank_name: string;
  branch_name: string;
  ifsc_code: string;
}

export interface UpdateKYCDetails {
  pan_img: string;
  pan_no: string;
  aadhar_img: string;
  aadhar_no: number;
}

export interface GetDistrict {
  state_id: number;
}

export interface ChangePassword {
  old_password: any;
  new_password: any;
  new_password_confirmation: any;
}

export default ProfileType;
