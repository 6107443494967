function InputFile({ inputName, handleImageChange }: any) {
  return (
    <>
      <label>{inputName}</label>
      <div className="form-group">
        <input
          type="file"
          className="form-control"
          onChange={handleImageChange}></input>
      </div>
    </>
  );
}

export default InputFile;
