const AttendanceType = {
  ADD_ATTENDANCE: "ADD_ATTENDANCE",
  GET_ATTENDANCE: "GET_ATTENDANCE",
  UPDATE_ATTENDANCE: "UPDATE_ATTENDANCE",
};

export interface AddAttendance {
  date: any;
  in_time: any;
  out_time: any;
}

export interface GetAttendance {
  search: any;
  limit: number;
  page: number;
}

export interface UpdateAttendance {
  id: number;
  date: any;
  in_time: any;
  out_time: any;
}

export default AttendanceType;
